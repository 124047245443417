<template>
    <div
        v-if="$showModal('reportComment')"
        id="report_response"
        class="custom_popup_wrapper report_popup"
    >
        <div class="custom_popup_inner my-mfp-slide-bottom">
            <div
                v-click-outside="close"
                class="custom_popup sm_size zoom-anim-dialog"
            >
                <button
                    class="btn_close_popup btn_reset bg_image jsCloseModal"
                    type="button"
                    @click="close"
                />

                <div v-if="submitted" class="response_form_recorded">
                    <div class="popup_title">
                        {{ $t('forum.reportModal.successTitle') }}
                    </div>

                    <div class="content_wrap">
                        <p>
                            {{ $t('forum.reportModal.successDescription') }}
                        </p>
                    </div>

                    <div class="btn_wrapper">
                        <button
                            class="btn btn_gray"
                            type="button"
                            @click="close"
                        >
                            {{ $t('forum.reportModal.successButton') }}
                        </button>
                    </div>
                </div>

                <div v-else class="response_form">
                    <div class="popup_title">
                        {{ $t('forum.reportModal.title') }}
                    </div>
                    <form>
                        <div class="form_row">
                            <div
                                v-for="reason in reasons"
                                :key="reason.value"
                                class="checkbox_row"
                            >
                                <label class="label_radio">
                                    <input
                                        v-model="selectedReason"
                                        type="radio"
                                        name="report"
                                        :value="reason.value"
                                    />

                                    <span class="radio_title">
                                        {{ reason.label }}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="form">
                            <label class="row_label">
                                {{ $t('forum.reportModal.message') }}
                            </label>

                            <div class="remarks">
                                <input
                                    v-model="message"
                                    class="form_control"
                                    type="text"
                                    :placeholder="
                                        $t(
                                            'forum.reportModal.messagePlaceholder'
                                        )
                                    "
                                    maxlength="200"
                                />

                                <button
                                    class="btn btn_gray"
                                    type="button"
                                    @click="submit"
                                >
                                    {{ $t('forum.reportModal.submitButton') }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            message: '',
            selectedReason: null,
            submitted: false
        };
    },

    computed: {
        ...mapGetters({
            commentId: 'modals/data'
        }),

        reasons() {
            return [
                {
                    value: 'inappropriate',
                    label: this.$t('forum.reportModal.reasons.inappropriate')
                },
                {
                    value: 'bad_file',
                    label: this.$t('forum.reportModal.reasons.badFile')
                },
                {
                    value: 'file_wrong_place',
                    label: this.$t('forum.reportModal.reasons.fileWrongPlace')
                },
                {
                    value: 'copyrights',
                    label: this.$t('forum.reportModal.reasons.copyrights')
                },
                {
                    value: 'other',
                    label: this.$t('forum.reportModal.reasons.other')
                }
            ];
        }
    },

    methods: {
        ...mapActions({
            report: 'comments/report'
        }),

        close() {
            this.message = '';
            this.selectedReason = null;
            this.submitted = false;

            this.$closeModal();
        },

        async submit() {
            if (!this.selectedReason) {
                return;
            }

            try {
                await this.report({
                    id: this.commentId,
                    reason: this.selectedReason,
                    message: this.message
                });

                this.submitted = true;
            } catch (err) {
                console.error(err);

                this.$toasted.global.error({
                    message: err.response?.data
                });
            }
        }
    }
};
</script>

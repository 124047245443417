import { render, staticRenderFns } from "./ReportComment.vue?vue&type=template&id=33bf75e5&"
import script from "./ReportComment.vue?vue&type=script&lang=js&"
export * from "./ReportComment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
export const defaultState = () => {};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    checkOtp(vuexContext, data) {
        return this.$axios.$post('auth/check-otp', data);
    },

    setNewPassword(vuexContext, data) {
        return this.$axios.$post('auth/set-new-password', data);
    },

    register(vuexContext, data) {
        return this.$axios.post('/auth/request-sign-up', data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

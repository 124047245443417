

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":["en","he"]},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.js","name":"English","domain":"/en"},{"code":"he","iso":"he-IL","file":"he.js","name":"עברית","domain":"/he"},{"code":"ar","iso":"ar-EG","file":"ar.js","name":"عربي","domain":"/ar"},{"code":"ru","iso":"ru-RU","file":"ru.js","name":"Русский","domain":"/ru"}],
  defaultLocale: "he",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/home/prpl/applications/cancer.staging/frontend-app/dictionaries",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"locale","cookieSecure":false,"fallbackLocale":"he","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.js","name":"English","domain":"/en"},{"code":"he","iso":"he-IL","file":"he.js","name":"עברית","domain":"/he"},{"code":"ar","iso":"ar-EG","file":"ar.js","name":"عربي","domain":"/ar"},{"code":"ru","iso":"ru-RU","file":"ru.js","name":"Русский","domain":"/ru"}],
  localeCodes: ["en","he","ar","ru"],
  additionalMessages: [],
}

export const localeMessages = {
  'en.js': () => import('../../../dictionaries/en.js' /* webpackChunkName: "lang-en.js" */),
  'he.js': () => import('../../../dictionaries/he.js' /* webpackChunkName: "lang-he.js" */),
  'ar.js': () => import('../../../dictionaries/ar.js' /* webpackChunkName: "lang-ar.js" */),
  'ru.js': () => import('../../../dictionaries/ru.js' /* webpackChunkName: "lang-ru.js" */),
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:`locale-${_vm.locale}`,staticClass:"header_menu",class:{ active: _vm.isMenuOpened }},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"custom_menu_wrapper"},[_c('div',{staticClass:"custom_menu"},[_c('div',{staticClass:"column main_menu_wrapper"},[_c('div',{staticClass:"menu_column"},[_c('div',{staticClass:"menu_column_row"},_vm._l((_vm.menuItems),function(item,index){return _c('div',{key:`menu-1-${index}`,staticClass:"link_row",on:{"mouseover":function($event){return _vm.firstMenuHover(item)}}},[_c('menu-link',{attrs:{"link":item,"class-names":[
                                    'icon_link js-open-sub-menu',
                                    {
                                        'no-more-items':
                                            !item?.items?.length
                                    }
                                ]}},[_c('span',{staticClass:"icon"},[(
                                            _vm.checkIfMenuIconVisible(
                                                item.icon
                                            )
                                        )?_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path",value:(item.icon),expression:"item.icon"}]}):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"title"},[_vm._v("\n                                    "+_vm._s(item.text)+"\n                                ")])])],1)}),0)])]),_vm._v(" "),_c('div',{staticClass:"column menu_level_2",class:{ active: _vm.secondMenuItems?.length }},[_c('div',{staticClass:"menu_column"},[_c('div',{staticClass:"menu_column_row"},_vm._l((_vm.secondMenuItems),function(item,index){return _c('div',{key:`menu-2-${index}`,staticClass:"link_row",on:{"mouseover":function($event){return _vm.secondMenuHover(item)}}},[_c('menu-link',{attrs:{"link":item,"class-names":[
                                    'icon_link js-open-sub-menu',
                                    {
                                        'no-more-items':
                                            !item?.items?.length
                                    }
                                ]}})],1)}),0)])]),_vm._v(" "),_c('div',{staticClass:"column menu_level_3",class:{ active: _vm.thirdMenuItems?.length }},[_c('div',{staticClass:"menu_column"},[_c('div',{staticClass:"menu_column_row"},_vm._l((_vm.thirdMenuItems),function(item,index){return _c('div',{key:`menu-3-${index}`,staticClass:"link_row"},[_c('menu-link',{attrs:{"link":item,"class-names":['icon_link']}})],1)}),0)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="menus_wrapper">
        <div class="menus_wrapper_inner">
            <div class="columns_wrapper flex justify_between row">
                <div
                    v-for="(menuColumn, index) in menuItems"
                    :key="`menu-column-${index}`"
                    class="menu_column"
                >
                    <div
                        class="block_label"
                        :class="{
                            active: isMenuActive(index)
                        }"
                        @click="toggleMenu(index)"
                    >
                        <h3>{{ menuColumn.text }}</h3>
                    </div>
                    <div
                        class="menu"
                        :class="{
                            active: isMenuActive(index)
                        }"
                    >
                        <ul>
                            <li
                                v-for="(item, itemIndex) in menuColumn.items"
                                :key="`menu-column-${index}-item-${itemIndex}`"
                            >
                                <menu-link :link="item" />
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="menu_column contact_column">
                    <div class="flex_column">
                        <div class="contact_row">
                            <div
                                class="block_label"
                                :class="{
                                    active: isMenuActive(contactColumnIndex)
                                }"
                                @click="toggleMenu(contactColumnIndex)"
                            >
                                {{ $t('footer.contact') }}
                            </div>
                            <div
                                class="tel_wrapper"
                                :class="{
                                    active: isMenuActive(contactColumnIndex)
                                }"
                            >
                                <div class="block_title">
                                    {{ $t('footer.contactLabel') }}
                                </div>
                                <div class="tel">
                                    <a href="tel:1800599995"> 1-800-599-995 </a>
                                </div>
                            </div>
                        </div>

                        <social-links />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SocialLinks from '@/components/common/SocialLinks';
import MenuLink from '@/components/layout/footer-items/Link';

export default {
    components: {
        MenuLink,
        SocialLinks
    },

    props: {
        menuItems: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            openMenus: [],
            contactColumnIndex: -1
        };
    },

    methods: {
        toggleMenu(index) {
            if (this.isMenuActive(index)) {
                this.openMenus = this.openMenus.filter(
                    menuIndex => menuIndex !== index
                );

                return;
            }

            this.openMenus.push(index);
        },

        isMenuActive(index) {
            return this.openMenus.includes(index);
        }
    }
};
</script>

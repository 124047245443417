<template>
    <header class="b_header box_shadow">
        <div class="container">
            <div class="header_wrapper flex align_center justify_between">
                <div class="right_part flex align_center">
                    <div class="mobile_menu_button">
                        <button
                            class="btn_reset btn_menu js-btn-mobile-menu"
                            :class="{ active: isMenuOpened }"
                            type="button"
                            @click.stop="toggleMenu"
                        />
                    </div>
                    <div class="logo_wrapper relative">
                        <nuxt-link class="link_logo" :to="localePath('/')">
                            <picture
                                :key="$i18n.locale"
                                v-full-image-path:assets="
                                    `logo_${$i18n.locale}.png`
                                "
                            />
                        </nuxt-link>
                    </div>
                    <div class="menu_button">
                        <button
                            class="btn_reset btn_menu js-btn-head-menu"
                            type="button"
                            @click.stop="toggleMenu"
                        >
                            {{ $t('header.menuButton') }}
                        </button>

                        <menu-desktop :menu-items="content.menuItems" />
                    </div>
                </div>
                <div class="left_wrapper flex">
                    <div class="controls_column">
                        <div class="contact_text">
                            <nuxt-link :to="localePath('/contact')">
                                {{ $t('header.contact') }}
                            </nuxt-link>
                        </div>
                    </div>
                    <div class="controls_column">
                        <div class="phone_link">
                            <a :href="`tel:${content.phone}`">
                                {{ content.phone }}
                            </a>
                        </div>
                    </div>
                    <div class="controls_column">
                        <div class="buttons_control flex align_center">
                            <div class="button_row">
                                <button
                                    class="btn_reset icon_notice has_notice jsOpenNotice"
                                    type="button"
                                    @click="openWhatsNewModal"
                                >
                                    <div class="icon">
                                        <picture
                                            v-full-image-path:assets="
                                                'icon_notice.svg'
                                            "
                                        />
                                    </div>
                                    <div class="title">
                                        {{ $t('header.whatsNew') }}
                                    </div>
                                </button>
                            </div>
                            <div class="button_row">
                                <button
                                    class="btn_reset icon_search"
                                    type="button"
                                    @click="setIsSearchOpened(true)"
                                >
                                    <div class="icon">
                                        <picture
                                            v-full-image-path:assets="
                                                'icon_search.svg'
                                            "
                                        />
                                    </div>
                                    <div class="title">
                                        {{ $t('header.searchButton') }}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="controls_column">
                        <div class="lang_wrapper drop_wrapper">
                            <button
                                class="btn_reset lang flex js-toggle-button"
                                type="button"
                                @click.stop="toggleLanguage"
                            >
                                {{ languageAbbreviations[locale] }}
                            </button>

                            <languages-desktop
                                v-if="$device.isDesktopOrTablet"
                            />
                        </div>
                        <div class="donation">
                            <a
                                href="https://donate.cancer.org.il/?utm_source=landing_page&utm_medium=cancer_org_il_btn_header"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="btn btn_red btn_icon_donation"
                            >
                                {{ $t('header.donateButton') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    class="desktop_head_search"
                    :class="{ active: isSearchOpened }"
                >
                    <div class="desktop_search_form">
                        <div class="search_form opened">
                            <div class="form_wrapper">
                                <form>
                                    <div class="form">
                                        <button
                                            class="btn_reset btn_gray_search"
                                            type="search"
                                            @click.prevent="
                                                goToSearchResultsPage
                                            "
                                        />
                                        <input
                                            v-model="search"
                                            class="form_control ltr-padding"
                                            type="text"
                                            :placeholder="
                                                $t('header.searchPlaceholder')
                                            "
                                            @input="onSearchInput"
                                        />
                                    </div>
                                </form>

                                <search-results-desktop
                                    v-if="showSearchResults"
                                    :results="results"
                                />
                            </div>
                            <button
                                class="btn_reset btn_hide_search js-close-search"
                                type="button"
                                @click="closeSearchBar"
                            />
                        </div>
                    </div>
                </div>
                <div class="mobile_part">
                    <div class="mobile_part_control flex">
                        <div class="button_row">
                            <button
                                class="btn_reset icon_search js-open-search-mobile"
                                type="button"
                                @click="setIsSearchOpened(true)"
                            >
                                <div class="icon">
                                    <picture
                                        v-full-image-path:assets="
                                            'icon_search.svg'
                                        "
                                    />
                                </div>
                            </button>
                        </div>
                        <div class="button_row">
                            <button
                                class="btn_reset icon_notice has_notice jsOpenNotice"
                                type="button"
                                @click="openWhatsNewModal"
                            >
                                <div class="icon">
                                    <picture
                                        v-full-image-path:assets="
                                            'icon_notice.svg'
                                        "
                                    />
                                </div>
                            </button>
                        </div>
                        <div class="button_row">
                            <button
                                class="btn_reset icon_tel js-open-call-now"
                                type="button"
                                @click="openContactDetails"
                            >
                                <div class="icon">
                                    <picture
                                        v-full-image-path:assets="
                                            'icon_tel.svg'
                                        "
                                    />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mobile_head_search" :class="{ active: isSearchOpened }">
            <div class="mobile_search_form">
                <div class="search_form">
                    <form>
                        <div class="form">
                            <button
                                class="btn_reset btn_search_icon"
                                type="search"
                                @click.prevent="goToSearchResultsPage"
                            />
                            <input
                                v-model="search"
                                class="form_control"
                                type="text"
                                :placeholder="$t('header.searchPlaceholder')"
                                @input="onSearchInput"
                            />
                            <button
                                class="btn_reset btn_hide_search js-close-search-mobile"
                                type="button"
                                @click="closeSearchBar"
                            />
                        </div>
                    </form>
                </div>
            </div>

            <search-results-mobile
                v-if="showSearchResults"
                :results="results"
            />
        </div>

        <contact-details :phone="content.phone" />
    </header>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

import languageAbbreviations from '@/helpers/languageAbbreviations';
import MenuDesktop from '@/components/layout/header-items/MenuDesktop';
import ContactDetails from '@/components/layout/header-items/ContactDetails';
import LanguagesDesktop from '@/components/layout/header-items/LanguagesDesktop';
import SearchResultsMobile from '@/components/layout/header-items/SearchResultsMobile';
import SearchResultsDesktop from '@/components/layout/header-items/SearchResultsDesktop';

export default {
    components: {
        MenuDesktop,
        ContactDetails,
        LanguagesDesktop,
        SearchResultsMobile,
        SearchResultsDesktop
    },

    data() {
        return {
            content: { menuItems: [] },
            search: '',
            results: {},
            limit: {
                categories: 4,
                articles: 4,
                forums: 4,
                medicationCategories: 0,
                clinicalResearchCategories: 0,
                branches: 0,
                newsflashes: 0
            },
            showSearchResults: false,
            languageAbbreviations
        };
    },

    async fetch() {
        try {
            this.content = await this.fetchContent('appHeader');
        } catch (error) {
            console.error(error);

            this.$toasted.global.error();
        }
    },

    computed: {
        ...mapGetters([
            'locale',
            'isMenuOpened',
            'isSearchOpened',
            'isSelectLanguageOpened'
        ])
    },

    watch: {
        '$route.path'() {
            this.closeSearchBar();
        },

        locale() {
            this.$fetch();
        }
    },

    methods: {
        ...mapActions({
            fetchContent: 'contents/show',
            fetchSearchedItems: 'search/index'
        }),

        ...mapMutations({
            setIsMenuOpened: 'SET_IS_MENU_OPENED',
            setIsSearchOpened: 'SET_IS_SEARCH_OPENED',
            setIsSelectLanguageOpened: 'SET_IS_SELECT_LANGUAGE_OPENED'
        }),

        openContactDetails() {
            this.$openModal('contactDetails');
        },

        openWhatsNewModal() {
            this.$openModal('whatsNew');
        },

        toggleMenu() {
            this.setIsMenuOpened(!this.isMenuOpened);
        },

        toggleLanguage() {
            this.setIsSelectLanguageOpened(!this.isSelectLanguageOpened);
        },

        async handleSearch() {
            if (!this.search) {
                return;
            }

            try {
                this.results = await this.fetchSearchedItems({
                    q: this.search,
                    limit: this.limit
                });
            } catch (error) {
                console.error(error);

                this.$toasted.global.error();
            }
        },

        onSearchInput() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            if (!this.search) {
                this.showSearchResults = false;

                return;
            }

            this.timer = setTimeout(async () => {
                await this.handleSearch();

                this.showSearchResults = true;
            }, 500);
        },

        goToSearchResultsPage() {
            this.$router.push(this.localePath(`/search?q=${this.search}`));
        },

        closeSearchBar() {
            this.search = '';
            this.results = {};
            this.showSearchResults = false;

            this.setIsSearchOpened(false);
        }
    }
};
</script>

<template>
    <div
        class="sub_menu_wrapper"
        :class="{
            active: !!items?.length
        }"
    >
        <div class="container">
            <div class="menu_title">
                <button
                    class="btn_back btn_reset"
                    type="button"
                    @click="$emit('go-back')"
                >
                    {{ title }}
                </button>
            </div>
            <div class="menu_list">
                <div
                    v-for="(item, index) in items"
                    :key="`submenu-${index}`"
                    class="link_row"
                >
                    <menu-link :link="item" class="icon_link">
                        <span class="title">
                            {{ item.text }}
                        </span>
                        <button
                            v-if="item?.items?.length"
                            type="button"
                            class="btn_submenu btn_reset"
                            @click.prevent="$emit('set-submenu', index)"
                        />
                    </menu-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuLink from '@/components/layout/header-items/menu-items/Link';

export default {
    components: {
        MenuLink
    },

    props: {
        title: {
            type: String,
            required: true
        },

        items: {
            type: Array,
            required: true
        }
    }
};
</script>

<template>
    <div class="mobile_main_menu" :class="{ open: isMenuOpened }">
        <div class="mobile_main_menu_inner">
            <div class="mm_menu_wrapper">
                <div class="mm_menu">
                    <div class="menu_container">
                        <div class="container">
                            <div class="menu_title">
                                {{ $t('header.menuButton') }}
                            </div>
                            <div class="menu_list">
                                <div
                                    v-for="(
                                        menuItem, menuIndex
                                    ) in content.menuItems"
                                    :key="`menu-mobile-${menuIndex}`"
                                    class="link_row"
                                >
                                    <menu-link
                                        :link="menuItem"
                                        :disabled="!!menuItem?.items?.length"
                                        :class-names="['icon_link']"
                                    >
                                        <span class="icon">
                                            <picture
                                                v-if="
                                                    checkIfMenuIconVisible(
                                                        menuItem.icon
                                                    )
                                                "
                                                v-full-image-path="
                                                    menuItem.icon
                                                "
                                            />
                                        </span>
                                        <span class="title">
                                            {{ menuItem.text }}
                                        </span>
                                        <button
                                            v-if="menuItem?.items?.length"
                                            type="button"
                                            class="btn_submenu btn_reset"
                                            @click.prevent="
                                                setSubmenu(menuIndex)
                                            "
                                        />
                                    </menu-link>

                                    <sub-menu-mobile
                                        v-if="menuIndex === fstLvlMenuLastIndex"
                                        :title="subMenuText"
                                        :items="visibleSubMenuItems"
                                        @go-back="goBack"
                                        @set-submenu="setSubmenu"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile_menu_buttons">
                <div class="donation">
                    <a
                        href="https://donate.cancer.org.il/?utm_source=landing_page&utm_medium=cancer_org_il_btn_header"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn_red"
                    >
                        {{ $t('header.donateButton') }}
                    </a>
                </div>
                <div class="buttons_list flex">
                    <div class="button_item">
                        <button
                            class="btn_reset"
                            type="button"
                            @click="goToVolunteersPage"
                        >
                            <div class="image">
                                <picture
                                    v-full-image-path:assets="
                                        'icon_volunteering.svg'
                                    "
                                />
                            </div>
                            <div class="title">
                                {{ $t('header.volunteering') }}
                            </div>
                        </button>
                    </div>
                    <div class="button_item">
                        <button
                            class="btn_reset"
                            type="button"
                            @click="goToContactPage"
                        >
                            <div class="image">
                                <picture
                                    v-full-image-path:assets="
                                        'icon_m_contact.svg'
                                    "
                                />
                            </div>
                            <div class="title">
                                {{ $t('header.contact') }}
                            </div>
                        </button>
                    </div>
                    <div class="button_item drop_wrapper">
                        <button
                            class="btn_reset"
                            type="button"
                            @click.stop="toggleLanguage"
                        >
                            <div class="image">
                                <picture
                                    v-full-image-path:assets="'icon_m_lang.svg'"
                                />
                            </div>
                            <div class="title language">
                                {{ languageAbbreviations[locale] }}
                            </div>
                        </button>

                        <languages-mobile />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import languageAbbreviations from '@/helpers/languageAbbreviations';
import MenuLink from '@/components/layout/header-items/menu-items/Link';
import LanguagesMobile from '@/components/layout/header-items/LanguagesMobile';
import SubMenuMobile from '@/components/layout/header-items/menu-items/SubMenuMobile';

export default {
    components: {
        MenuLink,
        SubMenuMobile,
        LanguagesMobile
    },

    data() {
        return {
            content: { menuItems: [] },
            subMenuIndexes: {
                first: null,
                second: null
            },
            languageAbbreviations
        };
    },

    async fetch() {
        try {
            this.content = await this.fetchContent('appHeader');
        } catch (error) {
            console.error(error);

            this.$toasted.global.error();
        }
    },

    computed: {
        ...mapGetters(['locale', 'isMenuOpened', 'isSelectLanguageOpened']),

        visibleSubMenuItems() {
            const { first, second } = this.subMenuIndexes;

            if (first !== null) {
                const firstItems = this.content.menuItems[first].items;

                return second !== null ? firstItems[second].items : firstItems;
            }

            return [];
        },

        subMenuText() {
            const { first, second } = this.subMenuIndexes;

            if (first !== null) {
                const firstItems = this.content.menuItems[first];

                return second !== null
                    ? firstItems.items[second].text
                    : firstItems.text;
            }

            return this.$t('header.menuButton');
        },

        fstLvlMenuLastIndex() {
            return this.content.menuItems.length - 1;
        }
    },

    watch: {
        '$route.path'() {
            this.close();
        }
    },

    methods: {
        ...mapMutations({
            setIsMenuOpened: 'SET_IS_MENU_OPENED',
            setIsSelectLanguageOpened: 'SET_IS_SELECT_LANGUAGE_OPENED'
        }),

        ...mapActions({
            fetchContent: 'contents/show'
        }),

        close() {
            this.setIsMenuOpened(false);

            this.subMenuIndexes.second = null;
            this.subMenuIndexes.first = null;
        },

        setSubmenu(index) {
            if (this.subMenuIndexes.first !== null) {
                this.subMenuIndexes.second = index;

                return;
            }

            this.subMenuIndexes.first = index;
        },

        goBack() {
            if (this.subMenuIndexes.second !== null) {
                this.subMenuIndexes.second = null;

                return;
            }

            this.subMenuIndexes.first = null;
        },

        checkIfMenuIconVisible(icon) {
            return !!icon && Object.keys(icon).length > 0;
        },

        toggleLanguage() {
            this.setIsSelectLanguageOpened(!this.isSelectLanguageOpened);
        },

        goToVolunteersPage() {
            this.$router.push(this.localePath('/volunteers'));
        },

        goToContactPage() {
            this.$router.push(this.localePath('/contact'));
        }
    }
};
</script>

'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index() {
        return this.$axios.$get('/forums');
    },

    show(ctx, slug) {
        return this.$axios.$get(`/forums/${slug}`);
    },

    managersList() {
        const { rows } = this.$axios.$get('forums/managers-list');

        return rows;
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

<template>
    <div class="result_row">
        <div class="row_title">
            {{ $t('search.forums') }}
        </div>
        <div class="links_wrapper flex">
            <div
                v-for="(forum, index) in forums?.rows"
                :key="index"
                class="link_row"
            >
                <nuxt-link
                    :to="localePath(`/forum/${forum.slug}`)"
                    class="link"
                >
                    <div class="title">
                        {{ forum.name }}
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
import getRandomIcon from '@/helpers/getRandomIcon';

export default {
    props: {
        forums: {
            type: Object,
            required: true
        }
    },

    methods: {
        getRandomIcon() {
            return getRandomIcon();
        }
    }
};
</script>

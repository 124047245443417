<template>
    <div v-if="$showModal('auth')" class="custom_popup_wrapper entrance_popup">
        <div class="custom_popup_inner">
            <div
                id="auth"
                v-click-outside="close"
                class="custom_popup sm_size zoom-anim-dialog"
            >
                <button
                    class="btn_close_popup btn_reset bg_image jsCloseModal"
                    type="button"
                    @click="close"
                />

                <div class="entrance_wrapper">
                    <div class="popup_body">
                        <div class="entrance_nav flex">
                            <div
                                v-for="tab in tabs"
                                :key="tab"
                                class="nav_row"
                                @click="onTabChange(tab)"
                            >
                                <button
                                    class="btn_reset btn_nav"
                                    :class="{ active: activeTab === tab }"
                                    type="button"
                                >
                                    {{ $t(`auth.${tab}.tab`) }}
                                </button>
                            </div>
                        </div>

                        <forgot-password
                            v-if="isForgotPasswordFormVisible"
                            @password-changed="onPasswordChanged"
                        />

                        <login-form
                            v-else-if="activeTab === 'login'"
                            :user-id="userId"
                            :email="email"
                            :strategy="strategy"
                            @forgot-password="
                                isForgotPasswordFormVisible = true
                            "
                            @login-success="onLoginSuccess"
                        />

                        <register v-else-if="activeTab === 'register'" />
                    </div>

                    <div
                        v-if="!isForgotPasswordFormVisible"
                        class="popup_footer"
                    >
                        <i18n :path="'auth.footerText'" tag="div" class="terms">
                            <span>
                                <nuxt-link
                                    :to="localePath('/terms')"
                                    @click.native="close"
                                >
                                    {{ $t('auth.termsLink') }}
                                </nuxt-link>
                                <br />
                            </span>

                            <span>
                                <nuxt-link
                                    :to="localePath('/privacy-policy')"
                                    @click.native="close"
                                >
                                    {{ $t('auth.privacyPolicyLink') }}
                                </nuxt-link>
                            </span>
                        </i18n>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import LoginForm from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';

export default {
    components: {
        Register,
        LoginForm,
        ForgotPassword
    },

    data() {
        return {
            email: '',
            strategy: '',
            userId: null,
            activeTab: 'login',
            isForgotPasswordFormVisible: false,
            tabs: ['login', 'register']
        };
    },

    computed: {
        ...mapGetters(['loggedUser'])
    },

    mounted() {
        const { email } = this.$route.query;

        if (!this.loggedUser && !!email) {
            this.$openModal('auth');
            this.activeTab = 'register';
        }
    },

    methods: {
        close() {
            this.$auth.$storage.removeCookie('redirect');

            this.$closeModal();

            this.isForgotPasswordFormVisible = false;
        },

        onPasswordChanged() {
            this.isForgotPasswordFormVisible = false;

            this.$closeModal();
        },

        onTabChange(tab) {
            this.activeTab = tab;
            this.isForgotPasswordFormVisible = false;
        },

        onLoginSuccess() {
            this.close();

            this.$openModal('success', {
                title: this.$t('forum.loggedInModal.title'),
                subtitle: this.$t('forum.loggedInModal.subtitle')
            });
        }
    }
};
</script>

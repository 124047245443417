'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(ctx, { type, slug, filters = {} }) {
        const params = {
            type,
            slug
        };

        params.filters = JSON.stringify(filters);

        return this.$axios.$get('/article-categories', {
            params
        });
    },

    show(ctx, slug) {
        return this.$axios.$get(`/article-categories/${slug}`);
    },

    subcategories(ctx, { slug, type }) {
        return this.$axios.$get(`/article-categories/${slug}/subcategories`, {
            params: { type }
        });
    },

    async getInformationByCategoryId(ctx, id) {
        const params = {
            id
        };

        const { rows } = await this.$axios.$get(
            `/article-categories/${id}/additional-information`,
            params
        );

        return rows;
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

<template>
    <div
        v-if="$showModal('whatsNew')"
        class="notice"
        :class="{
            active: $showModal('whatsNew')
        }"
    >
        <div class="notice__bg" />
        <div v-click-outside="$closeModal" class="notice__block">
            <div class="notice__block__head w100">
                <button class="btn_close" type="button" @click="$closeModal" />
                <div class="label_title">
                    {{ $t('whatsNew.header') }}
                </div>
            </div>
            <div class="notice__block__body w100">
                <div class="notice__cards__list" @scroll="handleScroll">
                    <div class="notice__list">
                        <popup-item
                            v-for="(item, index) in popupItems"
                            :key="index"
                            :item="item"
                        />
                    </div>
                </div>
            </div>
            <div class="notice__block__footer w100">
                <div class="buttons_wrapper flex">
                    <div class="button_row">
                        <button
                            class="btn btn_blue_empty"
                            @click="redirectToTarget('/newsflashes')"
                        >
                            {{ $t('whatsNew.newsflashes') }}
                        </button>
                    </div>
                    <div class="button_row">
                        <button
                            class="btn btn_blue"
                            @click="redirectToTarget('/events')"
                        >
                            {{ $t('whatsNew.events') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import concat from 'lodash/concat';
import { mapActions } from 'vuex';

import PopupItem from '@/components/whats-new/PopupItem';

export default {
    components: {
        PopupItem
    },

    data() {
        return {
            count: 0,
            pagination: {
                page: 1,
                perPage: 10
            },
            popupItems: [],
            scrollTolerance: 80,
            isFetchingNextPage: false,
            timestamp: this.$dayjs().unix()
        };
    },

    async fetch() {
        try {
            const { rows, count } = await this.fetchPopupItems({
                timestamp: this.timestamp,
                ...this.pagination
            });

            this.popupItems = rows;

            this.count = count;
        } catch (error) {
            console.error(error);

            this.$toasted.global.error();
        }
    },

    computed: {
        isLastPage() {
            const { page, perPage } = this.pagination;

            return page * perPage >= this.count;
        }
    },

    methods: {
        ...mapActions({
            fetchPopupItems: 'whatsNewPopup/index'
        }),

        async handleScroll({
            target: { scrollTop, clientHeight, scrollHeight }
        }) {
            const isScrollBreakpoint =
                scrollTop + clientHeight >= scrollHeight - this.scrollTolerance;

            if (
                isScrollBreakpoint &&
                !this.isLastPage &&
                !this.isFetchingNextPage
            ) {
                this.isFetchingNextPage = true;

                this.pagination.page++;

                const { rows } = await this.fetchPopupItems({
                    timestamp: this.$dayjs().unix(),
                    ...this.pagination
                });

                this.popupItems = concat(this.popupItems, rows);

                this.isFetchingNextPage = false;
            }
        },

        redirectToTarget(target) {
            this.$router.push(this.localePath(target));

            this.$closeModal();
        }
    }
};
</script>

'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(
        ctx,
        {
            currentPage = 1,
            perPage = 2,
            sortBy = ['createdAt:DESC'],
            forumIdOrSlug,
            search = ''
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            sortBy,
            forumIdOrSlug,
            ...(search && { q: search })
        };

        return this.$axios.$get('/posts', { params });
    },

    show(ctx, slug) {
        return this.$axios.$get(`/posts/${slug}`);
    },

    store(ctx, data) {
        return this.$axios.$post('/posts', data);
    },

    edit(ctx, { id, title, text }) {
        return this.$axios.$put(`/posts/${id}`, { title, text });
    },

    remove(ctx, id) {
        return this.$axios.$delete(`/posts/${id}`);
    },

    reply(ctx, { id, text }) {
        return this.$axios.$post(`/posts/${id}/reply`, {
            text
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

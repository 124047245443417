'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(
        ctx,
        {
            currentPage = 1,
            perPage = 10,
            sortBy = ['date:DESC'],
            type = null,
            fromDate = null,
            toDate = null,
            search = '',
            filters = {}
        } = {}
    ) {
        const params = {
            page: currentPage,
            perPage,
            sortBy,
            type,
            q: search
        };

        if (fromDate && toDate) {
            filters.date = {
                value: [fromDate, toDate],
                type: 'between'
            };
        } else if (fromDate) {
            filters.date = {
                value: fromDate,
                type: 'gte'
            };
        } else if (toDate) {
            filters.date = {
                value: toDate,
                type: 'lte'
            };
        }

        params.filters = JSON.stringify(filters);

        return this.$axios.$get('/events', {
            params
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entrance_form"},[_c('div',{staticClass:"popup_title text_center"},[_vm._v("\n        "+_vm._s(_vm.$t('auth.forgotPassword.password.title'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doSetNewPassword.apply(null, arguments)}}},[_c('div',{staticClass:"form_row"},[_c('label',{staticClass:"row_label"},[_vm._v("\n                    "+_vm._s(_vm.$t('auth.forgotPassword.password.passwordLabel'))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"password_wrap"},[((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.newPassword),expression:"formData.newPassword"}],staticClass:"form_control",attrs:{"placeholder":_vm.$t(
                                'auth.forgotPassword.password.passwordPlaceholder'
                            ),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.newPassword)?_vm._i(_vm.formData.newPassword,null)>-1:(_vm.formData.newPassword)},on:{"change":function($event){var $$a=_vm.formData.newPassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "newPassword", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "newPassword", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "newPassword", $$c)}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.newPassword),expression:"formData.newPassword"}],staticClass:"form_control",attrs:{"placeholder":_vm.$t(
                                'auth.forgotPassword.password.passwordPlaceholder'
                            ),"type":"radio"},domProps:{"checked":_vm._q(_vm.formData.newPassword,null)},on:{"change":function($event){return _vm.$set(_vm.formData, "newPassword", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.newPassword),expression:"formData.newPassword"}],staticClass:"form_control",attrs:{"placeholder":_vm.$t(
                                'auth.forgotPassword.password.passwordPlaceholder'
                            ),"type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.formData.newPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "newPassword", $event.target.value)}}}),_vm._v(" "),_c('button',{staticClass:"btn_reset btn_pass",class:{ active: _vm.showPassword },attrs:{"type":"button"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})])]),_vm._v(" "),_c('div',{staticClass:"form_row"},[_c('label',{staticClass:"row_label"},[_vm._v("\n                    "+_vm._s(_vm.$t(
                            'auth.forgotPassword.password.passwordConfirmationLabel'
                        ))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"password_wrap"},[((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.passwordConfirmation),expression:"formData.passwordConfirmation"}],staticClass:"form_control",attrs:{"placeholder":_vm.$t(
                                'auth.forgotPassword.password.passwordConfirmationPlaceholder'
                            ),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.passwordConfirmation)?_vm._i(_vm.formData.passwordConfirmation,null)>-1:(_vm.formData.passwordConfirmation)},on:{"change":function($event){var $$a=_vm.formData.passwordConfirmation,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "passwordConfirmation", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "passwordConfirmation", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "passwordConfirmation", $$c)}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.passwordConfirmation),expression:"formData.passwordConfirmation"}],staticClass:"form_control",attrs:{"placeholder":_vm.$t(
                                'auth.forgotPassword.password.passwordConfirmationPlaceholder'
                            ),"type":"radio"},domProps:{"checked":_vm._q(_vm.formData.passwordConfirmation,null)},on:{"change":function($event){return _vm.$set(_vm.formData, "passwordConfirmation", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.passwordConfirmation),expression:"formData.passwordConfirmation"}],staticClass:"form_control",attrs:{"placeholder":_vm.$t(
                                'auth.forgotPassword.password.passwordConfirmationPlaceholder'
                            ),"type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.formData.passwordConfirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "passwordConfirmation", $event.target.value)}}}),_vm._v(" "),_c('button',{staticClass:"btn_reset btn_pass",class:{ active: _vm.showPasswordConfirmation },attrs:{"type":"button"},on:{"click":function($event){_vm.showPasswordConfirmation =
                                !_vm.showPasswordConfirmation}}})])]),_vm._v(" "),_c('div',{staticClass:"btn_row"},[_c('button',{staticClass:"btn btn_gray",attrs:{"type":"submit","disabled":_vm.isFormDisabled}},[_vm._v("\n                    "+_vm._s(_vm.$t('auth.forgotPassword.password.submit'))+"\n                ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
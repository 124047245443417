<template>
    <div class="entrance_form">
        <div class="popup_title text_center">
            {{ $t('auth.forgotPassword.email.title') }}
        </div>

        <div class="form_content text_center">
            <p>
                {{ $t('auth.forgotPassword.email.subtitle') }}
            </p>
        </div>

        <div class="form">
            <form @submit.prevent="sendOtpRequest">
                <div class="form_row">
                    <label class="row_label">
                        {{ $t('auth.forgotPassword.email.emailLabel') }}
                    </label>

                    <input
                        v-model="formData.email"
                        class="form_control"
                        :class="{
                            error: !!getError('email')
                        }"
                        type="email"
                        :placeholder="
                            $t('auth.forgotPassword.email.emailPlaceholder')
                        "
                    />

                    <div class="invalid-field">
                        {{ getError('email') }}
                    </div>
                </div>
                <div class="btn_row">
                    <button
                        class="btn btn_gray"
                        type="submit"
                        :disabled="!formData.email"
                    >
                        {{ $t('auth.forgotPassword.email.submit') }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';

export default {
    validations: {
        formData: {
            email: {
                required,
                email
            }
        }
    },

    data() {
        return {
            formData: {
                email: ''
            }
        };
    },

    computed: {
        ...mapGetters(['isRtl', 'loggedUser'])
    },

    methods: {
        ...mapActions({
            sendEmailWithOtp: 'mails/sendEmailWithOtp'
        }),

        async sendOtpRequest() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.sendEmailWithOtp(this.formData);

                this.$emit('email-submitted', this.formData.email);

                this.formData.email = '';

                this.$v.$reset();
            } catch (err) {
                console.error(err);

                this.$toasted.global.error();
            }
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.$v.formData,
                [],
                key
            );
        }
    }
};
</script>

'use strict';

export const defaultState = () => ({
    MANAGER: 'manager',
    USER: 'user'
});

export const state = () => defaultState();

export const getters = {
    USER: state => state.USER,
    MANAGER: state => state.MANAGER
};

export const mutations = {};

export const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
};

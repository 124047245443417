<template>
    <div>
        <newsletter />

        <footer class="b_footer">
            <div class="footer_top">
                <div class="container">
                    <div class="top_wrapper row flex justify_between">
                        <footer-menu :menu-items="content.menuItems" />

                        <div class="contribution text_center">
                            <div class="block_label">
                                {{ $t('footer.contribution') }}
                            </div>
                            <div class="content">
                                <p>
                                    {{ $t('footer.withYourContribution') }}
                                </p>
                            </div>
                            <a
                                href="https://donate.cancer.org.il/?utm_source=landing_page&utm_medium=cancer_org_il_btn_header"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="btn btn_red btn_shadow btn_donation"
                            >
                                {{ $t('footer.forDonation') }}
                            </a>
                            <div class="award_wrapper">
                                <div
                                    class="award flex justify_center align_center"
                                >
                                    <div class="award_image">
                                        <picture
                                            v-full-image-path:assets="
                                                'award_01.png'
                                            "
                                        />
                                    </div>
                                    <div class="award_image">
                                        <picture
                                            v-full-image-path:assets="
                                                'award_02.png'
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_bottom">
                <div class="container">
                    <div class="bottom_wrapper flex row">
                        <div class="right_content">
                            <div class="content">
                                <i18n path="footer.longText.content" tag="p">
                                    <template #terms>
                                        <nuxt-link :to="localePath('/terms')">
                                            {{ $t('footer.longText.terms') }}
                                        </nuxt-link>
                                    </template>

                                    <template #privacy>
                                        <nuxt-link
                                            :to="localePath('/privacy-policy')"
                                        >
                                            {{ $t('footer.longText.privacy') }}
                                        </nuxt-link>
                                    </template>
                                </i18n>
                            </div>
                            <div class="info flex align_center">
                                <div class="copy_wrapper">
                                    <div class="copy">
                                        {{ $t('footer.allRightsReserved') }}
                                    </div>
                                </div>
                                <span>|</span>
                                <div class="powered flex align_center">
                                    <span>Powered By</span>

                                    <a
                                        href="https://www.prpl.io/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <picture
                                            v-full-image-path:assets="
                                                'logo_purple.svg'
                                            "
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="left_content">
                            <div class="title second_font">
                                <h3>{{ $t('footer.fightCancer') }}</h3>
                            </div>
                            <div class="bottom_menu flex justify_center">
                                <div class="menu_link block">
                                    <div class="icon">
                                        <picture
                                            v-full-image-path:assets="
                                                'icon_research.svg'
                                            "
                                        />
                                    </div>
                                    <div class="link_title">
                                        {{ $t('footer.research') }}
                                    </div>
                                </div>
                                <div class="menu_link block">
                                    <div class="icon">
                                        <picture
                                            v-full-image-path:assets="
                                                'icon_prevention_diagnosis.svg'
                                            "
                                        />
                                    </div>
                                    <div class="link_title">
                                        {{
                                            $t('footer.preventionAndDiagnosis')
                                        }}
                                    </div>
                                </div>
                                <div class="menu_link block">
                                    <div class="icon">
                                        <picture
                                            v-full-image-path:assets="
                                                'icon_rehabilitation.svg'
                                            "
                                        />
                                    </div>
                                    <div class="link_title">
                                        {{ $t('footer.rehabilitation') }}
                                    </div>
                                </div>
                                <div class="menu_link block">
                                    <div class="icon">
                                        <picture
                                            v-full-image-path:assets="
                                                'icon_professional_information.svg'
                                            "
                                        />
                                    </div>
                                    <div class="link_title">
                                        {{
                                            $t('footer.professionalInformation')
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="copy-mobile">
                <div class="info flex align_center">
                    <div class="powered flex align_center">
                        <span>Powered By</span>

                        <a
                            href="https://www.prpl.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <picture
                                v-full-image-path:assets="'purple_light.svg'"
                                class="copy-mobile-logo"
                            />
                        </a>
                    </div>
                    <div class="copy_wrapper">
                        <div class="copy">
                            {{ $t('footer.allRightsReserved') }}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FooterMenu from '@/components/layout/footer-items/Menu';
import Newsletter from '@/components/layout/footer-items/Newsletter';

export default {
    components: {
        FooterMenu,
        Newsletter
    },

    data() {
        return {
            content: { menuItems: [] }
        };
    },

    async fetch() {
        try {
            this.content = await this.fetchContent('appFooter');
        } catch (error) {
            console.error(error);

            this.$toasted.global.error();
        }
    },

    computed: {
        ...mapGetters(['locale'])
    },

    watch: {
        locale() {
            this.$fetch();
        }
    },

    methods: {
        ...mapActions({
            fetchContent: 'contents/show'
        })
    }
};
</script>

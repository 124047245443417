import 'dayjs/locale/he';
import 'dayjs/locale/ar';
import 'dayjs/locale/ru';

import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default ({ app: { $dayjs } }) => {
    $dayjs.extend(duration);
    $dayjs.extend(isBetween);
    $dayjs.extend(relativeTime);
    $dayjs.extend(customParseFormat);
};

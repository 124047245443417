var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"socials_row"},[_c('div',{staticClass:"block_title"},[_vm._v("\n        "+_vm._s(_vm.$t('footer.followUs'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"social_list_block"},[_c('div',{staticClass:"social_list flex"},[_c('div',{staticClass:"social_link"},[_c('a',{staticClass:"social-links-item",attrs:{"href":"https://www.youtube.com/user/cancerisrael","target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                            'social_image_youtube.svg'
                        ),expression:"\n                            'social_image_youtube.svg'\n                        ",arg:"assets"}]})])]),_vm._v(" "),_c('div',{staticClass:"social_link"},[_c('a',{staticClass:"social-links-item",attrs:{"href":"https://x.com/cancer_org_il?s=21&t=9RY_q_0sD6oLfNPaGP2KRg","target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                            'social_image_twitter.svg'
                        ),expression:"\n                            'social_image_twitter.svg'\n                        ",arg:"assets"}]})])]),_vm._v(" "),_c('div',{staticClass:"social_link"},[_c('a',{staticClass:"social-links-item",attrs:{"href":"https://www.instagram.com/israel_cancer_association/","target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                            'social_image_instagram.svg'
                        ),expression:"\n                            'social_image_instagram.svg'\n                        ",arg:"assets"}]})])]),_vm._v(" "),_c('div',{staticClass:"social_link"},[_c('a',{staticClass:"social-links-item",attrs:{"href":"https://www.facebook.com/cancer.org.il","target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                            'social_image_facebook.svg'
                        ),expression:"\n                            'social_image_facebook.svg'\n                        ",arg:"assets"}]})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
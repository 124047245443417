<template>
    <div class="mobile_donation_button" :class="{ closed: isClosed }">
        <div class="donation box_shadow">
            <a
                href="https://donate.cancer.org.il/?utm_source=landing_page&utm_medium=cancer_org_il_btn_header"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn_red"
            >
                {{ $t('header.donateButton') }}
            </a>
            <button
                class="btn_close btn_reset"
                type="button"
                @click="isClosed = true"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isClosed: false
        };
    }
};
</script>

export const defaultState = () => {};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    newsletterSignup(vuexContext, data) {
        return this.$axios.$post('/newsletter', data);
    },

    volunteeringContact(vuexContext, data) {
        return this.$axios.$post('/mails/volunteering-contact', data);
    },

    clinicalResearchContact(vuexContext, data) {
        return this.$axios.$post('/mails/clinical-research-contact', data);
    },

    sendEmailWithOtp(vuexContext, data) {
        return this.$axios.$post('/mails/send-otp', data);
    },

    contact(vuexContext, data) {
        return this.$axios.$post('/mails/contact', data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

import cookie from 'cookie';
import { StatusCodes } from 'http-status-codes';

const TOKEN_KEY = 'auth._token.local';

export default function ({ $axios, req, redirect, app }) {
    $axios.onError(error => {
        if (
            error.response &&
            error.response.status === StatusCodes.UNAUTHORIZED
        ) {
            if (process.server) {
                document.cookie = `${TOKEN_KEY}=''; path=/`;

                return redirect('/?modal=auth');
            }

            const { url } = error.response.config;
            const nonRedirectUrls = ['/auth/me', '/auth/login'];

            if (!nonRedirectUrls.find(item => item === url)) {
                return redirect('/?modal=auth');
            }
        }
    });

    $axios.onRequest(config => {
        $axios.defaults.headers.common['Accept-Language'] = app.i18n.locale;

        let CLUID;

        if (process.server && req.headers.cookie) {
            const cookies = cookie.parse(req.headers.cookie);

            CLUID = cookies[TOKEN_KEY];

            $axios.defaults.headers.Cookie = req.headers.cookie;
        } else if (process.client) {
            CLUID = localStorage[TOKEN_KEY] || '';
        }

        if (CLUID) {
            config.headers.cluid = CLUID;
        }
    });
}

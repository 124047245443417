import Vue from 'vue';

import { cdn, mediaUrl } from '@/config';

const FullBackgroundImagePath = {
    bind(el, binding) {
        const { value, arg, modifiers } = binding;

        if (!value) {
            return null;
        }

        if (arg === 'assets') {
            let imgUrl;
            let src = value;

            if (modifiers['load-webp'] && checkIfWebSupported()) {
                const [name] = src.split('.');

                src = `${name}.webp`;
            }

            if (cdn) {
                imgUrl = `url(${cdn}/_nuxt/img/${src})`;
            } else {
                imgUrl = `url(${require(`@/assets/images/${src}`)})`;
            }

            el.style.backgroundImage = imgUrl;

            return;
        }

        function createBackgroundImage() {
            if (!value.id) {
                let url;

                const img = arg === 'avatar' ? 'user.jpg' : 'no-image.png';

                if (cdn) {
                    url = `${cdn}/_nuxt/img/${img}`;
                } else {
                    url = require(`@/assets/images/${img}`);
                }

                el.style.backgroundImage = `url(${url})`;

                return;
            }

            const {
                date,
                extension,
                meta: { breakpoints },
                filename
            } = value;

            const isWebSupported = checkIfWebSupported();

            const selectedBreakPoint = findBreakPointToUse(
                breakpoints,
                el.offsetWidth
            );

            const url = `${cdn || mediaUrl}/images/${date}/${filename}${
                selectedBreakPoint ? `_${selectedBreakPoint}` : ''
            }.${isWebSupported ? 'webp' : extension}`;

            el.style.backgroundImage = `url(${url})`;
        }

        function handleIntersectForApi(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    createBackgroundImage();
                    observer.unobserve(el);
                }
            });
        }

        function createObserver(handler) {
            const options = {
                root: null,
                threshold: '0'
            };

            const observer = new IntersectionObserver(handler, options);
            observer.observe(el);
        }

        if (window.IntersectionObserver) {
            createObserver(handleIntersectForApi);

            return;
        }

        createBackgroundImage();
    }
};

Vue.directive('full-background-image-path', FullBackgroundImagePath);

function findBreakPointToUse(breakpoints, width) {
    if (!breakpoints || !breakpoints.length) {
        return null;
    }

    const breakPointsCopy = [...breakpoints];
    breakPointsCopy.sort((a, b) => b - a);

    if (breakPointsCopy[0] < width) {
        return null;
    }

    return breakPointsCopy.find((breakpoint, breakpointIndex) => {
        const nextBreakpoint = breakPointsCopy[breakpointIndex + 1];

        return (
            (breakpoint < width && nextBreakpoint < width) ||
            (breakpoint >= width &&
                (nextBreakpoint ? nextBreakpoint < width : true))
        );
    });
}

function checkIfWebSupported() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return !isSafari;
}

'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(ctx, { page = 1, perPage = 5, timestamp }) {
        const params = {
            page,
            perPage,
            timestamp
        };

        return this.$axios.$get('/whats-new-popup', {
            params
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

'use strict';
import { StatusCodes as HTTP } from 'http-status-codes';

export default ({ app, store }, inject) => {
    const { $toasted } = store._vm;

    inject('getServerErrors', error => {
        const { response } = error;

        if (response) {
            const { data, status } = response;

            if (status === HTTP.BAD_REQUEST && data.errors) {
                $toasted.global.error({
                    message: app.i18n.t('toasted.recheckForm')
                });

                return data.errors;
            }
        }

        $toasted.global.error();

        return [];
    });
};

<template>
    <div class="entrance_form">
        <div class="popup_title text_center">
            {{ $t('auth.forgotPassword.password.title') }}
        </div>

        <div class="form">
            <form @submit.prevent="doSetNewPassword">
                <div class="form_row">
                    <label class="row_label">
                        {{ $t('auth.forgotPassword.password.passwordLabel') }}
                    </label>

                    <div class="password_wrap">
                        <input
                            v-model="formData.newPassword"
                            class="form_control"
                            :type="showPassword ? 'text' : 'password'"
                            :placeholder="
                                $t(
                                    'auth.forgotPassword.password.passwordPlaceholder'
                                )
                            "
                        />

                        <button
                            class="btn_reset btn_pass"
                            :class="{ active: showPassword }"
                            type="button"
                            @click="showPassword = !showPassword"
                        />
                    </div>
                </div>

                <div class="form_row">
                    <label class="row_label">
                        {{
                            $t(
                                'auth.forgotPassword.password.passwordConfirmationLabel'
                            )
                        }}
                    </label>

                    <div class="password_wrap">
                        <input
                            v-model="formData.passwordConfirmation"
                            class="form_control"
                            :type="showPassword ? 'text' : 'password'"
                            :placeholder="
                                $t(
                                    'auth.forgotPassword.password.passwordConfirmationPlaceholder'
                                )
                            "
                        />

                        <button
                            class="btn_reset btn_pass"
                            :class="{ active: showPasswordConfirmation }"
                            type="button"
                            @click="
                                showPasswordConfirmation =
                                    !showPasswordConfirmation
                            "
                        />
                    </div>
                </div>
                <div class="btn_row">
                    <button
                        class="btn btn_gray"
                        type="submit"
                        :disabled="isFormDisabled"
                    >
                        {{ $t('auth.forgotPassword.password.submit') }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
    validations: {
        formData: {
            newPassword: {
                required,
                minLength: minLength(6),
                containsDigit: v => /\d/.test(v)
            },
            passwordConfirmation: {
                required,
                sameAsPassword: sameAs('newPassword')
            }
        }
    },

    props: {
        email: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            errorMessage: '',
            formData: {
                newPassword: '',
                passwordConfirmation: ''
            },
            showPassword: false,
            showPasswordConfirmation: false
        };
    },

    computed: {
        isFormDisabled() {
            return (
                !this.formData.newPassword ||
                !this.formData.passwordConfirmation
            );
        }
    },

    methods: {
        ...mapActions({
            setNewPassword: 'authentication/setNewPassword'
        }),

        async doSetNewPassword() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.setNewPassword({
                    email: this.email,
                    ...this.formData
                });

                this.$emit('password-changed');

                this.formData.newPassword = '';
                this.formData.passwordConfirmation = '';
            } catch (err) {
                const { response } = err;

                if (response) {
                    const {
                        data: { errorMessage }
                    } = response;

                    this.errorMessage = errorMessage;
                } else {
                    console.error(err);

                    this.$toasted.global.error();
                }
            }
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.$v.formData,
                [],
                key
            );
        }
    }
};
</script>

<template>
    <div>
        <nuxt-link
            v-if="!isExternalLink(link.redirect)"
            :to="localePath(link.redirect)"
            :target="link.blank ? '_blank' : ''"
        >
            {{ link.text }}
        </nuxt-link>

        <a
            v-else
            :href="link.redirect"
            :target="link.blank ? '_blank' : ''"
            rel="noopener noreferrer"
        >
            {{ link.text }}
        </a>
    </div>
</template>

<script>
import isExternalLink from '@/helpers/isExternalLink';

export default {
    props: {
        link: {
            type: Object,
            required: true
        }
    },

    methods: {
        isExternalLink(link) {
            return isExternalLink(link);
        }
    }
};
</script>

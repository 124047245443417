export const defaultState = () => ({
    content: []
});

export const state = () => defaultState();

export const getters = {
    getContent: state => state.content,
    getContentByKey: state => key =>
        state.content.find(item => item.key === key)
};

export const mutations = {
    SET_CONTENT(state, content) {
        state.content = content;
    },
    ADD_SINGLE_CONTENT_SECTION(state, content) {
        state.content = [...state.content, content];
    }
};

export const actions = {
    async index({ commit }, type = 'component') {
        const content = await this.$axios.$get('/contents', {
            params: { type }
        });

        commit('SET_CONTENT', content);
    },

    async show({ getters, commit, rootGetters }, key) {
        let content = getters.getContentByKey(key);

        if (!content) {
            content = await this.$axios.$get(
                `/contents/${key}-${rootGetters.locale}`
            );

            commit('ADD_SINGLE_CONTENT_SECTION', content);
        }

        return content.type === 'page'
            ? {
                  content: { id: content.id, ...content.value },
                  meta: content.meta,
                  ...(content?.tabs && {
                      tabs: content.tabs
                  })
              }
            : content.value;
    },

    async getInformationByContentId(ctx, id) {
        const params = {
            id
        };

        const { rows } = await this.$axios.$get(
            `/contents/${id}/additional-information`,
            params
        );

        return rows;
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

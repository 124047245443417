'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(ctx, regionSlug) {
        return this.$axios.$get('/branches', {
            params: {
                regionSlug
            }
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

<template>
    <component
        :is="isInternalLink(link.redirect) ? 'nuxt-link' : 'a'"
        v-bind="linkComponentOptions"
        :class="classNames"
        :target="link.blank ? '_blank' : ''"
        rel="noopener noreferrer"
    >
        <slot>{{ link.text }}</slot>
    </component>
</template>

<script>
export default {
    props: {
        link: {
            type: Object,
            required: true
        },

        classNames: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        linkComponentOptions() {
            const internalLink = this.isInternalLink(this.link.redirect);

            return {
                to: internalLink ? this.localePath(this.link.redirect) : null,
                href: internalLink ? null : this.link.redirect
            };
        }
    },

    methods: {
        isInternalLink(link) {
            return link.startsWith('/');
        }
    }
};
</script>

<template>
    <div class="entrance_form">
        <div class="form_content text_center">
            <p>{{ $t('auth.loginTitle') }}</p>
        </div>

        <div class="form">
            <form @submit.prevent="onSubmit">
                <div class="form_row">
                    <label class="row_label">
                        {{ $t('auth.nicknameField') }}
                    </label>

                    <input
                        v-model="formData.nickname"
                        class="form_control"
                        :class="{
                            error: !!getError('nickname') || errorMessage
                        }"
                        type="text"
                        :placeholder="$t('auth.nicknameFieldPlaceholder')"
                    />

                    <div class="error_message">
                        {{ getError('nickname') || errorMessage }}
                    </div>
                </div>

                <div class="form_row">
                    <label class="row_label">
                        {{ $t('auth.passwordField') }}
                    </label>

                    <div class="password_wrap">
                        <input
                            v-model="formData.password"
                            class="form_control"
                            :class="{
                                error: !!getError('password') || errorMessage
                            }"
                            :type="showPassword ? 'text' : 'password'"
                            :placeholder="$t('auth.passwordFieldPlaceholder')"
                        />

                        <button
                            class="btn_reset btn_pass"
                            :class="{
                                active: showPassword
                            }"
                            type="button"
                            @click="showPassword = !showPassword"
                        />
                    </div>

                    <div class="error_message">
                        {{ getError('password') || errorMessage }}
                    </div>
                </div>

                <div class="btn_row">
                    <button
                        class="btn btn_gray"
                        type="submit"
                        :disabled="!isAllFilled"
                    >
                        {{ $t('auth.logInButtonText') }}
                    </button>
                </div>
            </form>
        </div>
        <div class="forgot_pass">
            <div class="pass_title">
                {{ $t('auth.login.forgotPassword') }}
                <a @click="onForgotPassword"> {{ $t('general.clickHere') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
    validations: {
        formData: {
            nickname: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(25),
                mustBeValidNickname: value =>
                    /^[a-zA-Z0-9\u0590-\u05FF]+$/.test(value)
            },
            password: { required }
        }
    },

    data() {
        return {
            showPassword: false,
            errorMessage: '',
            formData: {
                nickname: '',
                password: ''
            }
        };
    },

    computed: {
        ...mapGetters({
            isRtl: 'isRtl',
            loggedUser: 'loggedUser'
        }),

        isAllFilled() {
            return this.formData.nickname && this.formData.password;
        }
    },

    methods: {
        async onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.$auth.loginWith('local', {
                    data: {
                        ...this.formData
                    }
                });

                this.$emit('login-success');
            } catch (err) {
                const { response } = err;

                if (response) {
                    const {
                        data: { errorMessage }
                    } = response;

                    this.errorMessage = this.$t(errorMessage);
                } else {
                    console.error(err);

                    this.$toasted.global.error();
                }
            }
        },

        onForgotPassword() {
            this.$emit('forgot-password');
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.$v.formData,
                [],
                key
            );
        }
    }
};
</script>

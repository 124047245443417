<template>
    <div class="result_row">
        <div class="row_title">
            {{ $t('search.categories') }}
        </div>
        <div class="links_wrapper flex">
            <div
                v-for="(category, index) in categories?.rows"
                :key="index"
                class="link_row"
            >
                <nuxt-link
                    :to="localePath(`/categories/${category.slug}`)"
                    class="link"
                >
                    <div class="title">
                        {{ category.name }}
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        categories: {
            type: Object,
            required: true
        }
    }
};
</script>

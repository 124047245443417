<template>
    <div class="search_result_wrapper">
        <div class="search_result">
            <div v-if="noResultsFlag" class="search_no_results_info">
                {{ $t('search.noResultsFound') }}
            </div>

            <categories-section
                v-if="categories?.rows?.length"
                :categories="categories"
            />

            <articles-section
                v-if="articles?.rows?.length"
                :articles="articles"
            />

            <forums-section v-if="forums?.rows?.length" :forums="forums" />
        </div>
    </div>
</template>

<script>
import ForumsSection from '@/components/layout/header-items/search-desktop/ForumsSection';
import ArticlesSection from '@/components/layout/header-items/search-desktop/ArticlesSection';
import CategoriesSection from '@/components/layout/header-items/search-desktop/CategoriesSection';

export default {
    components: {
        ForumsSection,
        ArticlesSection,
        CategoriesSection
    },

    props: {
        results: {
            type: Object,
            required: true
        }
    },

    computed: {
        categories() {
            return this.results?.categories || {};
        },

        articles() {
            return this.results?.articles || {};
        },

        forums() {
            return this.results?.forums || {};
        },

        noResultsFlag() {
            return !(
                this.categories?.rows?.length ||
                this.articles?.rows?.length ||
                this.forums?.rows?.length
            );
        }
    }
};
</script>

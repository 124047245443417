<template>
    <div class="entrance_form">
        <div class="popup_title text_center">
            {{ $t('auth.forgotPassword.otp.title') }}
        </div>
        <div class="form_content text_center">
            <p>{{ subtitle }}</p>
        </div>
        <div class="form">
            <form @submit.prevent="sendOtp">
                <div class="form_row">
                    <div class="code_wrapper flex">
                        <input
                            v-for="(_, index) in otpCode"
                            :key="index"
                            :ref="`input-${index}`"
                            v-model.number="otpCode[index]"
                            class="otp-field"
                            :class="{
                                error: errorMessage
                            }"
                            type="text"
                            maxlength="1"
                            inputmode="numeric"
                            autocomplete="off"
                            @paste="onPaste"
                            @keyup="setFocus($event, index)"
                        />
                    </div>

                    <div v-if="errorMessage" class="error_message">
                        {{ errorMessage }}
                    </div>
                </div>

                <div class="btn_row">
                    <button
                        class="btn btn_gray"
                        type="submit"
                        :disabled="!canBeSubmitted"
                    >
                        {{ $t('general.send') }}
                    </button>
                </div>
            </form>
        </div>
        <div class="forgot_pass">
            <div class="pass_title">
                {{ $t('auth.emailNotReceived') }}
                <a @click="resendEmail">
                    {{ $t('general.sendAgain') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        email: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            otpCode: ['', '', '', ''],
            errorMessage: '',
            isEmailSent: false
        };
    },

    computed: {
        subtitle() {
            if (this.isEmailSent) {
                return this.$t('auth.forgotPassword.otp.subtitleResent', {
                    email: this.email
                });
            }

            return this.$t('auth.forgotPassword.otp.subtitle');
        },

        canBeSubmitted() {
            return this.otpCode.every(code => code);
        }
    },

    methods: {
        ...mapActions({
            checkOtp: 'authentication/checkOtp',
            sendEmailWithOtp: 'mails/sendEmailWithOtp'
        }),

        async sendOtp() {
            try {
                await this.checkOtp({
                    email: this.email,
                    otp: this.otpCode.join('')
                });

                this.$emit('otp-verified');
            } catch (err) {
                const { response } = err;

                if (response) {
                    const {
                        data: { errorMessage }
                    } = response;

                    this.errorMessage = errorMessage;
                } else {
                    console.error(err);

                    this.$toasted.global.error();
                }
            }
        },

        onPaste(event) {
            const numbersOnly = /^\d+$/;
            const pasted = event.clipboardData.getData('text/plain');

            if (numbersOnly.test(pasted)) {
                const numbers = pasted.split('');

                numbers.slice(0, 4).forEach((number, index) => {
                    this.otpCode[index] = number;
                });
            }
        },

        setFocus(event, index) {
            const enterKeys = [13, 'Enter'];
            const backKeys = [8, 46, 'Backspace'];

            const key =
                event.key || event.which || event.keyCode || event.charCode;

            if (enterKeys.includes(key)) {
                return;
            }

            const id = backKeys.includes(key) ? index - 1 : index + 1;
            const [input] = this.$refs[`input-${id}`] || [];

            input?.focus();
        },

        resendEmail() {
            this.otpCode = ['', '', '', ''];
            this.errorMessage = '';

            this.sendEmailWithOtp({ email: this.email });
            this.isEmailSent = true;
        }
    }
};
</script>

<template>
    <div
        v-if="$showModal('contactDetails')"
        v-click-outside="close"
        class="popup_call_now"
        :class="{ active: $showModal('contactDetails') }"
    >
        <div class="call_now">
            <div class="call_now_inner">
                <div class="title">{{ $t('header.callNow') }}</div>
                <div class="phone_link">
                    <a :href="`tel:${phone}`">
                        {{ phone }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        phone: {
            type: String,
            default: ''
        }
    },

    methods: {
        close() {
            this.$closeModal();
        }
    }
};
</script>

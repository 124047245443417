export const defaultState = () => {};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(
        vuexContext,
        {
            q,
            limit = {
                categories: 8,
                articles: 8,
                forums: 8,
                medicationCategories: 8,
                clinicalResearchCategories: 8,
                branches: 8,
                newsflashes: 8
            }
        }
    ) {
        return this.$axios.$get('/search', {
            params: {
                q,
                limit
            }
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

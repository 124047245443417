<template>
    <div class="entrance_form">
        <div class="form_content text_center">
            <p>{{ $t('auth.register.title') }}</p>
        </div>
        <div class="form">
            <form @submit.prevent="onSubmit">
                <div class="form_row">
                    <label class="row_label">
                        {{ $t('auth.emailField') }}
                    </label>

                    <input
                        v-model="formData.email"
                        class="form_control"
                        type="email"
                        :placeholder="$t('auth.emailFieldPlaceholder')"
                        :class="{
                            error: !!getError('email') || errorMessage
                        }"
                    />

                    <div class="error_message">
                        {{ getError('email') }}
                    </div>
                </div>

                <div class="form_row">
                    <label class="row_label">
                        {{ $t('auth.nicknameField') }}
                    </label>

                    <input
                        v-model="formData.nickname"
                        class="form_control"
                        type="text"
                        :placeholder="$t('auth.nicknameFieldPlaceholder')"
                        :class="{
                            error: !!getError('nickname') || errorMessage
                        }"
                    />

                    <div class="error_message">
                        {{ getError('nickname') }}
                    </div>
                </div>

                <div class="form_row">
                    <label class="row_label">
                        {{ $t('auth.passwordField') }}
                    </label>

                    <div class="password_wrap">
                        <input
                            v-model="formData.password"
                            class="form_control"
                            :type="showPassword ? 'text' : 'password'"
                            :placeholder="$t('auth.passwordFieldPlaceholder')"
                            :class="{
                                error: !!getError('password') || errorMessage
                            }"
                        />

                        <button
                            class="btn_reset btn_pass"
                            :class="{
                                active: showPassword
                            }"
                            type="button"
                            @click="showPassword = !showPassword"
                        />
                    </div>

                    <div class="error_message">
                        {{ getError('password') }}
                    </div>
                </div>
                <div class="btn_row">
                    <button
                        class="btn btn_gray"
                        type="submit"
                        :disabled="!allIsFilled"
                    >
                        {{ $t('auth.register.submit') }}
                    </button>
                </div>
                <div class="checkbox_row">
                    <div class="checkbox_wrap">
                        <label class="label_checkbox">
                            <input
                                v-model="formData.notifications"
                                type="checkbox"
                            />
                            <span class="checkbox_title">
                                {{ $t('auth.register.notifications') }}
                            </span>
                        </label>
                    </div>

                    <div class="checkbox_wrap">
                        <label class="label_checkbox">
                            <input
                                v-model="formData.newsletter"
                                type="checkbox"
                            />
                            <span class="checkbox_title">
                                {{ $t('auth.register.newsletter') }}
                            </span>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {
    required,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { StatusCodes } from 'http-status-codes';

export default {
    data() {
        return {
            formData: {
                email: '',
                nickname: '',
                password: '',
                notifications: true,
                newsletter: true
            },
            errorMessage: '',
            serverErrors: [],
            showPassword: false
        };
    },

    computed: {
        allIsFilled() {
            return !!(
                this.formData.email &&
                this.formData.nickname &&
                this.formData.password
            );
        }
    },

    validations: {
        formData: {
            email: {
                required,
                email
            },
            nickname: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(25),
                mustBeValidNickname: value =>
                    /^[a-zA-Z0-9\u0590-\u05FF]+$/.test(value)
            },
            password: {
                required,
                minLength: minLength(6),
                containsDigit: v => /\d/.test(v)
            }
        }
    },

    methods: {
        ...mapActions({
            register: 'authentication/register'
        }),

        async onSubmit() {
            this.serverErrors = [];

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.register(this.formData);

                this.$openModal('success', {
                    title: this.$t('forum.registeredModal.title'),
                    subtitle: this.$t('forum.registeredModal.subtitle')
                });
            } catch (error) {
                const { response } = error;

                if (response) {
                    const { data, status } = response;

                    this.errorMessage = data.errorMessage;

                    if (status === StatusCodes.BAD_REQUEST && data.errors) {
                        this.serverErrors = data.errors;

                        const [firstError] = data.errors;

                        this.$toasted.global.error({
                            message:
                                firstError?.param === 'id'
                                    ? firstError.message
                                    : this.$t('toasted.recheckForm')
                        });

                        return;
                    }
                }

                console.error(error);

                this.$toasted.global.error();
            }
        },

        close() {
            this.$closeModal();
        },

        back() {
            this.formData.email = '';
            this.formData.nickname = '';
            this.formData.password = '';
            this.formData.notifications = false;
            this.formData.newsletter = false;
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.$v.formData,
                this.serverErrors,
                key
            );
        }
    }
};
</script>

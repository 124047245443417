<template>
    <div
        v-if="$showModal('success')"
        id="logged_successfully"
        class="custom_popup_wrapper report_popup"
    >
        <div class="custom_popup_inner my-mfp-slide-bottom mfp-ready">
            <div
                v-click-outside="$closeModal"
                class="custom_popup sm_size zoom-anim-dialog"
            >
                <button
                    class="btn_close_popup btn_reset bg_image jsCloseModal"
                    type="button"
                    @click="$closeModal"
                />

                <div class="logged_successfully">
                    <div class="icon_block">
                        <picture
                            v-full-image-path:assets="'successfully_icon.svg'"
                        />
                    </div>

                    <div class="popup_title">{{ title }}</div>
                    <div class="popup_content">
                        <p>
                            {{ subtitle }}
                        </p>
                    </div>
                    <div class="btn_wrap">
                        <button
                            class="btn btn_gray"
                            type="button"
                            @click="$closeModal"
                        >
                            {{ $t('general.ok') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            modalData: 'modals/data'
        }),

        title() {
            return this.modalData?.title;
        },

        subtitle() {
            return this.modalData?.subtitle;
        }
    }
};
</script>

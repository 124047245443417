<template>
    <div class="content-wrapper">
        <app-header />

        <menu-mobile />

        <whats-new-modal />

        <main>
            <nuxt />
        </main>

        <app-footer />

        <auth-modal />

        <success-modal />

        <report-comment-modal />

        <donate-modal />

        <portal-target name="modals" multiple />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AuthModal from '@/components/modals/Auth';
import AppHeader from '@/components/layout/Header';
import AppFooter from '@/components/layout/Footer';
import DonateModal from '@/components/modals/Donate';
import SuccessModal from '@/components/modals/Success';
import WhatsNewModal from '@/components/modals/WhatsNew';
import ReportCommentModal from '@/components/modals/ReportComment';
import MenuMobile from '@/components/layout/header-items/MenuMobile';

export default {
    components: {
        AuthModal,
        AppHeader,
        AppFooter,
        MenuMobile,
        DonateModal,
        SuccessModal,
        WhatsNewModal,
        ReportCommentModal
    },

    head() {
        return {
            htmlAttrs: {
                dir: ['he', 'ar'].includes(this.$i18n.locale) ? 'rtl' : 'ltr'
            },
            bodyAttrs: {
                class: this.isScrollingBlocked ? 'notice_open' : ''
            }
        };
    },

    computed: {
        ...mapGetters({
            isMenuOpened: 'isMenuOpened',
            isModalOpen: 'modals/isOpen'
        }),

        isScrollingBlocked() {
            return this.isModalOpen || this.isMenuOpened;
        }
    }
};
</script>

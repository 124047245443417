export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    add(ctx, { postId, text, commentId }) {
        return this.$axios.$post(`/posts/${postId}/comment`, {
            text,
            commentId
        });
    },

    remove(ctx, id) {
        return this.$axios.$delete(`/comments/${id}`);
    },

    edit(ctx, { id, text }) {
        return this.$axios.$put(`/comments/${id}`, { text });
    },

    report(ctx, { id, reason, message }) {
        return this.$axios.$post(`/comments/${id}/report`, { reason, message });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

'use strict';

export const defaultState = () => {
    return {};
};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(ctx, { search = '' } = {}) {
        const params = {
            q: search
        };

        return this.$axios.$get('/medication-categories', {
            params
        });
    },

    show(ctx, medicationCategoryIdOrSlug) {
        return this.$axios.$get(
            `/medication-categories/${medicationCategoryIdOrSlug}`
        );
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

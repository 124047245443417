'use strict';

export const state = () => ({
    LIST_PAGE: 'List Page',
    SUBCATEGORY_PAGE: 'Subcategory Page'
});

export const getters = {
    ALL_TYPES: state => [state.LIST_PAGE, state.SUBCATEGORY_PAGE],
    LIST_PAGE: state => state.LIST_PAGE,
    SUBCATEGORY_PAGE_TYPE: state => state.SUBCATEGORY_PAGE
};

export const mutations = {};

export const actions = {
    index(ctx, { sortBy = ['createdAt:DESC'] } = {}) {
        const params = {
            sortBy
        };

        return this.$axios.$get(`/clinical-research-categories`, {
            params
        });
    },

    show(ctx, idOrSlug) {
        return this.$axios.$get(`/clinical-research-categories/${idOrSlug}`);
    },

    getSubcategories(ctx, idOrSlug) {
        return this.$axios.$get(
            `/clinical-research-categories/${idOrSlug}/subcategories`
        );
    },

    getResearchList(ctx, idOrSlug) {
        return this.$axios.$get(
            `/clinical-research-categories/${idOrSlug}/research-list`
        );
    },

    search(ctx, params) {
        return this.$axios.$get('/clinical-research-categories/search', {
            params
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

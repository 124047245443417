<template>
    <div
        :key="`locale-${locale}`"
        class="header_menu"
        :class="{ active: isMenuOpened }"
    >
        <div v-click-outside="close" class="custom_menu_wrapper">
            <div class="custom_menu">
                <div class="column main_menu_wrapper">
                    <div class="menu_column">
                        <div class="menu_column_row">
                            <div
                                v-for="(item, index) in menuItems"
                                :key="`menu-1-${index}`"
                                class="link_row"
                                @mouseover="firstMenuHover(item)"
                            >
                                <menu-link
                                    :link="item"
                                    :class-names="[
                                        'icon_link js-open-sub-menu',
                                        {
                                            'no-more-items':
                                                !item?.items?.length
                                        }
                                    ]"
                                >
                                    <span class="icon">
                                        <picture
                                            v-if="
                                                checkIfMenuIconVisible(
                                                    item.icon
                                                )
                                            "
                                            v-full-image-path="item.icon"
                                        />
                                    </span>
                                    <span class="title">
                                        {{ item.text }}
                                    </span>
                                </menu-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="column menu_level_2"
                    :class="{ active: secondMenuItems?.length }"
                >
                    <div class="menu_column">
                        <div class="menu_column_row">
                            <div
                                v-for="(item, index) in secondMenuItems"
                                :key="`menu-2-${index}`"
                                class="link_row"
                                @mouseover="secondMenuHover(item)"
                            >
                                <menu-link
                                    :link="item"
                                    :class-names="[
                                        'icon_link js-open-sub-menu',
                                        {
                                            'no-more-items':
                                                !item?.items?.length
                                        }
                                    ]"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="column menu_level_3"
                    :class="{ active: thirdMenuItems?.length }"
                >
                    <div class="menu_column">
                        <div class="menu_column_row">
                            <div
                                v-for="(item, index) in thirdMenuItems"
                                :key="`menu-3-${index}`"
                                class="link_row"
                            >
                                <menu-link
                                    :link="item"
                                    :class-names="['icon_link']"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import MenuLink from '@/components/layout/header-items/menu-items/Link';

export default {
    components: {
        MenuLink
    },

    props: {
        menuItems: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            secondMenuItems: null,
            thirdMenuItems: null
        };
    },

    computed: {
        ...mapGetters(['locale', 'isMenuOpened'])
    },

    watch: {
        '$route.path'() {
            this.close();
        }
    },

    methods: {
        ...mapMutations({
            setIsMenuOpened: 'SET_IS_MENU_OPENED'
        }),

        close() {
            if (window.innerWidth < 992) {
                return;
            }

            this.setIsMenuOpened(false);

            this.secondMenuItems = null;
            this.thirdMenuItems = null;
        },

        firstMenuHover(item) {
            this.secondMenuItems = item.items || null;
            this.thirdMenuItems = null;
        },

        secondMenuHover(item) {
            this.thirdMenuItems = item.items || null;
        },

        checkIfMenuIconVisible(icon) {
            return !!icon && Object.keys(icon).length > 0;
        }
    }
};
</script>

export default function ({ $gtm }) {
    $gtm.push({
        originalLocation:
            document.location.protocol +
            '//' +
            document.location.hostname +
            document.location.pathname +
            document.location.search
    });
}

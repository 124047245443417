var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entrance_form"},[_c('div',{staticClass:"form_content text_center"},[_c('p',[_vm._v(_vm._s(_vm.$t('auth.loginTitle')))])]),_vm._v(" "),_c('div',{staticClass:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form_row"},[_c('label',{staticClass:"row_label"},[_vm._v("\n                    "+_vm._s(_vm.$t('auth.nicknameField'))+"\n                ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.nickname),expression:"formData.nickname"}],staticClass:"form_control",class:{
                        error: !!_vm.getError('nickname') || _vm.errorMessage
                    },attrs:{"type":"text","placeholder":_vm.$t('auth.nicknameFieldPlaceholder')},domProps:{"value":(_vm.formData.nickname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "nickname", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"error_message"},[_vm._v("\n                    "+_vm._s(_vm.getError('nickname') || _vm.errorMessage)+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"form_row"},[_c('label',{staticClass:"row_label"},[_vm._v("\n                    "+_vm._s(_vm.$t('auth.passwordField'))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"password_wrap"},[((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form_control",class:{
                            error: !!_vm.getError('password') || _vm.errorMessage
                        },attrs:{"placeholder":_vm.$t('auth.passwordFieldPlaceholder'),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.password)?_vm._i(_vm.formData.password,null)>-1:(_vm.formData.password)},on:{"change":function($event){var $$a=_vm.formData.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "password", $$c)}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form_control",class:{
                            error: !!_vm.getError('password') || _vm.errorMessage
                        },attrs:{"placeholder":_vm.$t('auth.passwordFieldPlaceholder'),"type":"radio"},domProps:{"checked":_vm._q(_vm.formData.password,null)},on:{"change":function($event){return _vm.$set(_vm.formData, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form_control",class:{
                            error: !!_vm.getError('password') || _vm.errorMessage
                        },attrs:{"placeholder":_vm.$t('auth.passwordFieldPlaceholder'),"type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "password", $event.target.value)}}}),_vm._v(" "),_c('button',{staticClass:"btn_reset btn_pass",class:{
                            active: _vm.showPassword
                        },attrs:{"type":"button"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})]),_vm._v(" "),_c('div',{staticClass:"error_message"},[_vm._v("\n                    "+_vm._s(_vm.getError('password') || _vm.errorMessage)+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"btn_row"},[_c('button',{staticClass:"btn btn_gray",attrs:{"type":"submit","disabled":!_vm.isAllFilled}},[_vm._v("\n                    "+_vm._s(_vm.$t('auth.logInButtonText'))+"\n                ")])])])]),_vm._v(" "),_c('div',{staticClass:"forgot_pass"},[_c('div',{staticClass:"pass_title"},[_vm._v("\n            "+_vm._s(_vm.$t('auth.login.forgotPassword'))+"\n            "),_c('a',{on:{"click":_vm.onForgotPassword}},[_vm._v(" "+_vm._s(_vm.$t('general.clickHere')))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="notice__card" @click="redirectToArticle">
        <div class="card_top card_control">
            <div class="card_right_part" />
            <div class="card_left_part">
                <div class="categories_list">
                    <div :class="['category_label', categoryClass]">
                        {{ type }}
                    </div>
                </div>
            </div>
        </div>
        <div class="card_bottom card_control">
            <div class="card_right_part">
                <div class="date">{{ day }}</div>
                <div class="month">{{ month }}</div>
            </div>
            <div class="card_left_part">
                <div class="content">
                    <p>
                        {{ article.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import camelCase from 'lodash/camelCase';
import { mapActions, mapGetters } from 'vuex';

import months from '@/helpers/months';
import { ArticleType } from '@/enums/articles';

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            article: {}
        };
    },

    async fetch() {
        try {
            this.article = await this.fetchArticle(this.item.articleId);
        } catch (error) {
            console.error(error);

            this.$toasted.global.error();
        }
    },

    computed: {
        ...mapGetters(['locale']),

        day() {
            return this.$dayjs(this.item.date).format('DD');
        },

        month() {
            return months[this.$dayjs(this.item.date).month()][this.locale];
        },

        type() {
            const parsedType = camelCase(this.article.type);

            return parsedType ? this.$t(`whatsNew.${parsedType}`) : '';
        },

        categoryClass() {
            return this.article.type === ArticleType.NEWSFLASH
                ? 'flash'
                : 'event';
        }
    },

    methods: {
        ...mapActions({
            fetchArticle: 'articles/show'
        }),

        redirectToArticle() {
            this.$router.push(this.$getArticleLink(this.item.article));

            this.$closeModal();
        }
    }
};
</script>

import Vue from 'vue';
import Toasted from 'vue-toasted';

export default ({ app }) => {
    Vue.use(Toasted, {
        duration: 3000,
        position: 'bottom-center',
        theme: 'bubble'
    });

    Vue.toasted.register(
        'error',
        payload => {
            const { message } = payload;

            if (!message) {
                return app.i18n.t('toasted.defaultError');
            }

            return message;
        },
        {
            type: 'error'
        }
    );
};

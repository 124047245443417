<template>
    <div class="b_error_page">
        <div class="page_decor absolute inset-0 -z-1">
            <picture v-full-image-path:assets="'error_page_decor.svg'" />
        </div>

        <div class="container">
            <div class="page_content flex">
                <div class="page_content_inner">
                    <div class="content">
                        <h1>404</h1>

                        <p>
                            <strong>{{ $t('errorPage.pageNotFound') }}</strong>
                        </p>
                    </div>

                    <div class="btn_wrapper">
                        <nuxt-link :to="localePath('/')" class="btn btn_gray">
                            {{ $t('errorPage.goToHomepage') }}
                        </nuxt-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0);
    }
};
</script>

const { upperFirst } = require('lodash');

class GetValidationErrorMessageHandler {
    constructor(app) {
        this.app = app;
    }

    handle(validation, serverErrors, key, index) {
        const item = this.#getItem(validation, key, index);
        const serverError = this.#getServerError(serverErrors, key, index);

        if (serverError) {
            return serverError;
        }

        if (!item || !item.$error) {
            return '';
        }

        const { $params } = item;

        for (const key in $params) {
            if (!item[key]) {
                return this.#getError(key, item.$params);
            }
        }

        return this.app.i18n.t('validation.default');
    }

    #getItem(validation, key, index) {
        if (isNaN(index)) {
            return validation[key];
        }

        return validation.$each[index][key];
    }

    #getServerError(serverErrors, key, index) {
        if (!isNaN(index)) {
            // @TODO
            return '';
        }

        if (serverErrors.length) {
            const errorItem = serverErrors.find(error => error.param === key);

            if (errorItem) {
                return errorItem.message;
            }
        }

        return '';
    }

    #getError(key, params = {}) {
        const messagesTable = {
            required: this.app.i18n.t('validation.required'),
            isFormatValid: this.app.i18n.t('validation.isFormatValid'),
            email: this.app.i18n.t('validation.email'),
            atLeastOneItem: this.app.i18n.t('validation.atLeastOneItem'),
            isFullName: this.app.i18n.t('validation.isFullName'),
            maxElements: this.app.i18n.t('validation.maxElements'),
            size: this.app.i18n.t('validation.size'),
            integer: this.app.i18n.t('validation.integer'),
            containsDigit: this.app.i18n.t('validation.containsDigit'),
            maxLength: this.app.i18n.t('validation.maxLength', {
                max: params?.maxLength?.max
            }),
            minValue: this.app.i18n.t('validation.minValue', {
                min: params?.minValue?.min
            }),
            maxValue: this.app.i18n.t('validation.maxValue', {
                max: params?.maxValue?.max
            }),
            minLength: this.app.i18n.t('validation.minLength', {
                min: params?.minLength?.min
            }),
            containsDigits: this.app.i18n.t('validation.containsDigits'),
            sameAs: this.app.i18n.t('validation.sameAs', {
                eq: upperFirst(params?.sameAs?.eq)
            }),
            sameAsPassword: 'Must be the same as password',
            isPositive: this.app.i18n.t('validation.isPositive'),
            mustBeValidNickname: this.app.i18n.t('validation.nicknamePattern'),
            maxWords: this.app.i18n.t('validation.maxWords', {
                max: params?.maxWords?.max
            })
        };

        return messagesTable[key] || this.app.i18n.t('validation.default');
    }
}

export default GetValidationErrorMessageHandler;

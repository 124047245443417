<template>
    <div class="b_stay_on_top">
        <div class="block_decor absolute">
            <picture v-full-image-path:assets="'bg_stay_on_top.svg'" />
        </div>
        <div class="container">
            <div class="block_wrapper flex flex_center justify_between">
                <div class="form_info">
                    <div class="sub_title">
                        {{ $t('newsletter.subtitle') }}
                    </div>
                    <div class="title">
                        {{ $t('newsletter.signUp') }}
                    </div>
                </div>
                <div class="form_wrapper">
                    <form @submit.prevent="joinNewsletter">
                        <div class="form">
                            <div class="form_row">
                                <input
                                    id="name"
                                    v-model="name"
                                    class="form_control"
                                    type="text"
                                    :placeholder="
                                        $t('newsletter.namePlaceholder')
                                    "
                                />
                            </div>
                            <div class="form_row">
                                <input
                                    id="email"
                                    v-model="email"
                                    class="form_control"
                                    :class="{
                                        'is-invalid': !!getError('email')
                                    }"
                                    type="text"
                                    :placeholder="
                                        $t('newsletter.emailPlaceholder')
                                    "
                                />
                            </div>
                            <div class="btn_row">
                                <input
                                    class="btn btn_blue_empty"
                                    type="submit"
                                    :value="$t('newsletter.joinButton')"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { StatusCodes } from 'http-status-codes';
import { required, email } from 'vuelidate/lib/validators';

export default {
    validations: {
        email: {
            required,
            email
        }
    },

    data() {
        return {
            email: '',
            name: '',
            serverErrors: []
        };
    },

    methods: {
        ...mapActions({
            newsletterSignup: 'mails/newsletterSignup'
        }),

        async joinNewsletter() {
            try {
                this.serverErrors = [];

                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                }

                await this.newsletterSignup({
                    email: this.email,
                    name: this.name
                });

                this.$v.$reset();
                this.email = '';
                this.name = '';
                this.$toasted.success(this.$t('newsletter.thankYouMessage'));
            } catch (error) {
                const { response } = error;

                if (response) {
                    const { data, status } = response;

                    if (status === StatusCodes.BAD_REQUEST && data.errors) {
                        this.serverErrors = data.errors;

                        this.$toasted.global.error({
                            message: this.$t('toasted.recheckForm')
                        });

                        return;
                    }
                }

                console.error(error);

                this.$toasted.global.error();
            }
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.$v,
                this.serverErrors,
                key
            );
        }
    }
};
</script>

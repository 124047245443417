'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    confirmEmail(ctx, token) {
        return this.$axios.$post(`/users/email-confirmation/${token}`);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

<template>
    <component
        :is="`${step}-step`"
        :email="email"
        @email-submitted="onEmailSubmitted"
        @password-changed="onPasswordChanged"
        @otp-verified="onOtpVerified"
    />
</template>

<script>
import OtpStep from './Otp';
import EmailStep from './Email';
import PasswordStep from './Password';

export default {
    components: {
        OtpStep,
        EmailStep,
        PasswordStep
    },

    data() {
        return {
            email: '',
            step: 'email'
        };
    },

    methods: {
        onEmailSubmitted(email) {
            this.email = email;

            this.step = 'otp';
        },

        onOtpVerified() {
            this.step = 'password';
        },

        onPasswordChanged() {
            this.$emit('password-changed');
        }
    }
};
</script>

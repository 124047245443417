export default [
    {
        en: 'January',
        he: 'בינואר',
        ar: 'يناير',
        ru: 'Января'
    },
    {
        en: 'February',
        he: 'בפברואר',
        ar: 'شهر فبراير',
        ru: 'Февраля'
    },
    {
        en: 'March',
        he: 'במרץ',
        ar: 'يمشي',
        ru: 'Марта'
    },
    {
        en: 'April',
        he: 'באפריל',
        ar: 'أبريل',
        ru: 'Апреля'
    },
    {
        en: 'May',
        he: 'במאי',
        ar: 'يمكن',
        ru: 'Мая'
    },
    {
        en: 'June',
        he: 'ביוני',
        ar: 'يونيو',
        ru: 'Июня'
    },
    {
        en: 'July',
        he: 'ביולי',
        ar: 'يوليو',
        ru: 'Июля'
    },
    {
        en: 'August',
        he: 'באוגוסט',
        ar: 'أغسطس',
        ru: 'Августа'
    },
    {
        en: 'September',
        he: 'בספטמבר',
        ar: 'سبتمبر',
        ru: 'Сентября'
    },
    {
        en: 'October',
        he: 'באוקטובר',
        ar: 'اكتوبر',
        ru: 'Октября'
    },
    {
        en: 'November',
        he: 'בנובמבר',
        ar: 'شهر نوفمبر',
        ru: 'Ноября'
    },
    {
        en: 'December',
        he: 'בדצמבר',
        ar: 'ديسمبر',
        ru: 'Декабря'
    }
];

export const defaultState = () => ({
    isMenuOpened: false,
    isSearchOpened: false,
    isSelectLanguageOpened: false,
    locale: 'en',
    alphabets: {
        en: 'abcdefghijklmnopqrstuvwxyz',
        he: 'אבגדהוזחטיכלמנסעפצקרשת',
        ar: 'abcdefghijklmnopqrstuvwxyz',
        ru: 'abcdefghijklmnopqrstuvw'
    }
});

export const state = () => defaultState();

export const getters = {
    loggedUser: state => state.auth?.user,
    isMenuOpened: state => state.isMenuOpened,
    isSearchOpened: state => state.isSearchOpened,
    isSelectLanguageOpened: state => state.isSelectLanguageOpened,
    locale: state => state.locale,
    isLtr: state => ['en', 'ru'].includes(state.locale),
    isRtl: state => ['he', 'ar'].includes(state.locale),
    direction: (state, getters) => (getters.isLtr ? 'ltr' : 'rtl'),
    alphabet: state => state.alphabets[state.locale]
};

export const mutations = {
    SET_IS_MENU_OPENED(state, val) {
        state.isMenuOpened = val;
    },

    SET_IS_SEARCH_OPENED(state, val) {
        state.isSearchOpened = val;
    },

    SET_IS_SELECT_LANGUAGE_OPENED(state, val) {
        state.isSelectLanguageOpened = val;
    },

    SET_LOCALE(state, locale) {
        state.locale = locale;
    }
};

export const actions = {
    async nuxtServerInit(
        { dispatch, commit },
        {
            app: {
                i18n: { locale }
            }
        }
    ) {
        await Promise.all([dispatch('contents/index')]);

        commit('SET_LOCALE', locale);
    },

    setLocale({ commit }, locale) {
        commit('SET_LOCALE', locale);
        this.$cookies.set('locale', locale);
    }
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menus_wrapper"},[_c('div',{staticClass:"menus_wrapper_inner"},[_c('div',{staticClass:"columns_wrapper flex justify_between row"},[_vm._l((_vm.menuItems),function(menuColumn,index){return _c('div',{key:`menu-column-${index}`,staticClass:"menu_column"},[_c('div',{staticClass:"block_label",class:{
                        active: _vm.isMenuActive(index)
                    },on:{"click":function($event){return _vm.toggleMenu(index)}}},[_c('h3',[_vm._v(_vm._s(menuColumn.text))])]),_vm._v(" "),_c('div',{staticClass:"menu",class:{
                        active: _vm.isMenuActive(index)
                    }},[_c('ul',_vm._l((menuColumn.items),function(item,itemIndex){return _c('li',{key:`menu-column-${index}-item-${itemIndex}`},[_c('menu-link',{attrs:{"link":item}})],1)}),0)])])}),_vm._v(" "),_c('div',{staticClass:"menu_column contact_column"},[_c('div',{staticClass:"flex_column"},[_c('div',{staticClass:"contact_row"},[_c('div',{staticClass:"block_label",class:{
                                active: _vm.isMenuActive(_vm.contactColumnIndex)
                            },on:{"click":function($event){return _vm.toggleMenu(_vm.contactColumnIndex)}}},[_vm._v("\n                            "+_vm._s(_vm.$t('footer.contact'))+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"tel_wrapper",class:{
                                active: _vm.isMenuActive(_vm.contactColumnIndex)
                            }},[_c('div',{staticClass:"block_title"},[_vm._v("\n                                "+_vm._s(_vm.$t('footer.contactLabel'))+"\n                            ")]),_vm._v(" "),_vm._m(0)])]),_vm._v(" "),_c('social-links')],1)])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tel"},[_c('a',{attrs:{"href":"tel:1800599995"}},[_vm._v(" 1-800-599-995 ")])])
}]

export { render, staticRenderFns }
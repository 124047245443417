export const ArticleType = {
    DRUG: 'drug',
    EVENT: 'event',
    NEWSFLASH: 'newsflash',
    SPEAKER_MESSAGE: 'speaker_message',
    BRANCH_ACTIVITIES: 'branch_activities',
    CLINICAL_RESEARCH: 'clinical_research',
    RIGHTS_AND_SERVICES: 'rights_and_services',
    SIMPLE_ARTICLE: 'simple_article'
};

export const ArticleSubtype = {
    RIGHTS_AND_SERVICES: {
        OTHER: 'other',
        GENERAL_INFORMATION: 'general_information',
        NPO_AND_ORGANIZATIONS: 'npo_and_organizations',
        BENEFITS_AND_OBLIGATIONS: 'benefits_and_obligations',
        GOVERNMENT_AND_AUTHORITIES: 'government_and_authorities'
    }
};

export const ArticleStatus = {
    DRAFT: 'draft',
    PUBLISHED: 'published'
};

'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    submit(ctx, { email }) {
        return this.$axios.$post('/volunteer-emails/submit', { email });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

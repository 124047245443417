export const defaultState = () => {};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    signOut(vuexContext, { id, email }) {
        const params = { id, email };

        return this.$axios.$post('/newsletter/unsubscribe', params);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

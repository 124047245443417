<template>
    <div class="result_row">
        <div class="row_title">
            {{ $t('search.articles') }}
        </div>
        <div class="links_articles flex">
            <div
                v-for="(article, index) in articles?.rows"
                :key="index"
                class="link_row"
            >
                <nuxt-link :to="$getArticleLink(article)" class="link">
                    <div class="title">
                        {{ article.name }}
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        articles: {
            type: Object,
            required: true
        }
    }
};
</script>
